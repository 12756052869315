<template>
  <!-- @click="queryClick($event)" -->
  <div class="cont" @click="queryClick($event)">
    <div class="paymentContainer" :style="{ display: paymentCenterShow ? 'block' : 'none' }">
      <payment-center ref="paymentCont" :paymentForm="paymentForm" :needPaymentMusicId="musicId" :musicName="musicName"
        @closePaymentCenter="closePaymentCenter" @payAudioLoadingOver="payAudioLoadingOver">
      </payment-center>
    </div>
    <div class="home-container">
      <div class="home-title-container">
        <div class="title-leftContainer">
          <div class="title">BGM猫</div>
          <div class="title-explain"> 一键生成视频/播客配乐和片头音乐</div>
          <div class="content-tab" :class="showBurstPoint ? 'TabWordFirst' : 'TabWordSecond'">
            <div class="tab-word">
              <span :class="showBurstPoint ? 'choosedMusic' : ''" @click="changeShowBurstPoint(0)">视频配乐</span>
              <span :class="showBurstPoint ? '' : 'choosedMusic'" @click="changeShowBurstPoint(1)">片头音乐</span>
            </div>
            <div class="tip-prompt"></div>
          </div>
        </div>
      </div>
      <div id="inputContent_box">
        {{ inputContent }}
      </div>
      <div class="home-userInput-container">
        <div class="duration-container">
          <div class="duration-content">
            <div>
              <p>输入时长</p>
              <p v-if="showBurstPoint">[30秒～5分钟]</p>
              <p v-if="!showBurstPoint">[1秒～30秒]</p>
            </div>
            <input v-if="showBurstPoint" class="inputDuration" v-on:keydown="changeDuration($event)" @click="intoInput"
              :value="duration1" />
            <input v-if="!showBurstPoint" class="input-duration" placeholder="0" onfocus="this.placeholder=''"
              onblur="this.placeholder='0'" v-model="duration2" v-on:keydown="inspectDuration($event)" type="Number" />
            <span v-if="!showBurstPoint"
              style="font-size: 16px;margin-top: 42px;margin-left: 5px;color: #B8B8B8;">秒</span>
            <div v-if="showBurstPoint" class="tips-prompt" :style="inputIndex == -1
              ? 'left: -1000px;'
              : inputIndex == 0
                ? 'left: -75px;'
                : inputIndex == 2
                  ? 'left: -45px;'
                  : 'left: -22px;'
              ">
            </div>
          </div>
        </div>
        <div class="userInput-top-cotainer">
          <div class="input-container" :class="hoverInputOrLabel === 1 ? 'greyContainer' : ''" @click="changeInputCon(0)">
            <div class="borderBox">
              <i class="iconfont icon-duihao" :style="hoverInputOrLabel === 0 ? 'display:block;' : ''"></i>
            </div>
            <span>输入描述</span>
            <input type="text" placeholder="写下你的创意" v-model="inputContent" autocomplete="off" @focus="inputFocus()"
              ref="inputContent" id="inputContent" />
          </div>
          <div class="label-container" :class="hoverInputOrLabel === 0 ? 'greyContainer' : ''" @click="changeInputCon(1)">
            <div class="borderBox" :style="hoverInputOrLabel === 1 ? 'position:relative; top: -4px;' : ''">
              <i class="iconfont icon-duihao" :style="hoverInputOrLabel === 1 ? 'display:block;' : ''"></i>
            </div>
            <div class="labelTitle">
              选择标签
              <div class="attention">
                <img src="../assets/paymentCenter/attention.png" />
                <div class="attention-container">
                  <div class="jiantou"></div>
                  <div class="attention-detail">
                    <div>友情提示：每一个标签组至多选择一个标签</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="choosedLabel">
              <div class="choosedLabel-number">
                <i class="iconfont icon-icon_label_20"></i>
                <span>
                  <span :style="choosedArr.length > 0 ? 'color: #999;' : ''">{{
                    choosedArr.length
                  }}</span>/3
                </span>
              </div>
              <div class="choosedLabel-content">
                <p class="nothing-choosed" v-if="choosedArr.length == 0">
                  #请选择感兴趣的标签
                </p>
                <div v-if="choosedArr.length > 0">
                  <div class="choosedlabel-content" v-for="(item, index) in choosedArr" :key="index">
                    <span class="justContent">
                      {{ item }}
                      <i class="iconfont icon-icon_lable_close_12" @click="deleteLabel(index)"></i>
                    </span>
                    <!-- <span class="or" v-if="index != choosedArr.length - 1"
                      >or</span> -->
                  </div>
                </div>
                <div v-if="choosedArr.length > 0" style="height: 8px"></div>
              </div>
            </div>
            <div class="label-content">
              <a-tabs default-active-key="1">
                <a-tab-pane class="labelArr sceneArr-content" key="1" tab="风格">
                  <span class="transmitStyle" v-for="(item, index) in genreArr" :key="index"
                    :class="choosedArr.indexOf(item) > -1 ? 'sceneStyle' : ''" @click="chooseStyle(1, item)">
                    {{ item }}
                  </span>
                </a-tab-pane>
                <a-tab-pane class="labelArr genreArr-content" key="2" tab="场景">
                  <span class="transmitStyle" v-for="(item, index) in sceneArr" :key="index"
                    :class="choosedArr.indexOf(item) > -1 ? 'genreStyle' : ''" @click="chooseStyle(2, item)">
                    {{ item }}
                  </span>
                </a-tab-pane>
                <a-tab-pane class="labelArr moodArr-content" key="3" tab="心情" force-render>
                  <span class="transmitStyle" v-for="(item, index) in moodArr" :key="index"
                    :class="choosedArr.indexOf(item) > -1 ? 'moodStyle' : ''" @click="chooseStyle(3, item)">
                    {{ item }}
                  </span>
                </a-tab-pane>
              </a-tabs>
            </div>
          </div>
        </div>
        <div class="button-container">
          <div class="home-generation">
            <v-btn class="ma-2 createButton" :class="buttonState ? '' : 'cantcreate'" :loading="loading"
              :disabled="loading" color="secondary" @click="createMusic">
              生成
            </v-btn>
          </div>
          <div class="createAudioNum-noLogin" v-if="createAudioNumNoLogin > -1">
            今日剩余:{{ createAudioNumNoLogin }}<span @click="collectionLoginSystm">登录</span>后可无限生成
          </div>
        </div>
      </div>
      <div class="home-bottom">
        <div class="home-player" v-show="audioShow">
          <player ref="player" :wavesurferAdr="wavesurferAdr" :burstPointTime="burstPointTime" :musicId="musicId"
            :structureTimeArr="structureTimeArr" :burstConArr="burstConArr" :structurePowerArr="structurePowerArr"
            :renderTip="renderTip" @loadFinish="loadFinish" @createAgain="createAgain"
            @changeSliderShow="changeSliderShow" @collectionLoginSystm="collectionLoginSystm"
            @setDownLoadContainerShow="setDownLoadContainerShow">
          </player>
        </div>
        <!-- v-if="audioShow && $store.state.userGrade != -1" -->
        <div class="home-download" v-if="audioShow" :style="downLoadContainerShow ? 'display:block;' : 'display: none;'">
          <download-container ref="downloadCon" :musicId="musicId" :musicName="musicName" :wavesurferAdr="wavesurferAdr"
            @collectionLoginSystm="collectionLoginSystm" @closeDownloadContainer="closeDownloadContainer"
            @payAudioLoadingOver="payAudioLoadingOver">
          </download-container>
        </div>
        <div class="home-beian">
          <span class="composer">北京灵动音科技有限公司</span>
          ｜
          <a class="beianhao" href="https://beian.miit.gov.cn" target="_blank">
            京ICP备19028267号-2
          </a>
          <a class="beianhao" target="_blank"
            href="https://download.lazycomposer.com/product/bgmmao/static/%E7%BD%91%E6%96%87%E8%AF%81%E4%B9%A6_bgmcat.com.pdf">
            京网文（2023）1075-030号
          </a>
          <span class="beianhao">
            Copyright © 2021 DeepMusic. All rights reserved.
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { send_record } from "../utils/umeng_util";
import { axiosGet, axiosPost } from "../../src/axios.js"
import { LABELARR, FILEINFO, SOUNDTRACK } from "../../src/api.js"
import Player from "./player"
import DownloadContainer from './downloadContainer'
import PaymentCenter from './paymentCenter.vue'
import { data_process_new, checkOld, addOld } from "../../src/utils/util.js";
export default {
  components: {
    Player,
    DownloadContainer,
    PaymentCenter
  },
  data: () => ({
    paymentForm: '',
    // homePayBecomeVip 开通会员
    // homePayBuyOnlyOne 单曲购买

    paymentCenterShow: false,
    downLoadContainerShow: false,
    volumeSliderShow: false,

    inputContent: "",//输入灵感
    hoverInputOrLabel: -1,
    // 鼠标所在区域属于输入描述还是选择标签区域 -1 加载 0 input 1label

    loading: false, // 生成按钮的加载状态
    duration1: 0, // 输入时长
    duration2: Number, // 输入时长
    lastestDuration: 0, // 输入时长
    inputIndex: -1, // 输入时长下标
    genreArr: [], // 风格数组
    moodArr: [], // 心情数组
    sceneArr: [], // 场景数组
    choosedArr: [], // 被选择的数组
    buttonState: false, // 生成按钮可点击状态
    audioShow: false, // 播放器是否显示
    renderTip: true,
    musicName: '',
    wavesurferAdr: '', // 音频地址
    burstPointTime: -1,  // 高能点
    musicId: -1, // 音频ID
    structureTimeArr: [],
    structurePowerArr: [],
    burstConArr: [], // 控制高能点范围
    showBurstPoint: true, // 控制高能点生成
    localBurstArr: [], // 上次高能点段落
    localFirstBurst: true, // 是否第一次生成带高能点的音频
    localLabelId: 0, // 上次歌曲id
    localPhraseTime: 0, // 段长
    beforePhraseTime: 0, // 处理前段长
    localBurstIndex: 0, // 高能点在序列中所处的位置
    localburstPointTime: 0, // 初始高能点时间
    localPhraseBeforeBurst: 0, // 高能点前段落长度
    offsetSpeed: 1,  // 速度变化
    offsetPhrase: 0,  // 段落长度变化
    startHalf: 0,

    loginStep: 1, // 登录步骤  0注册登录页面 1密码登录页面

    createAudioNumNoLogin: 3, //未登录状态3次生成次数
  }),
  filters: {
    delayDuration(dur) {
      const duration = Math.round(dur)
      const min = Math.floor(duration / 60)
      const sec = (duration % 60) > 9 ? (duration % 60) : '0' + (duration % 60)
      return min + ':' + sec
    }
  },
  watch: {
    inputContent: function (val, oldVal) {
      let w1 = document.getElementById("inputContent")
      let w2 = document.getElementById("inputContent_box")
      let FontSize = (window.getComputedStyle(w1, null).fontSize).slice(0, -2)
      let size;
      this.$nextTick(() => {
        if (val.length >= oldVal.length) {
          // 输入
          if (w2.offsetWidth >= w1.offsetWidth) {
            if (FontSize >= 18) {
              size = 26 - Math.floor((w2.offsetWidth - w1.offsetWidth) / 10) > 18 ? 26 - Math.floor((w2.offsetWidth - w1.offsetWidth) / 10) : 18;
              if (size > FontSize) return
              w1.style.fontSize = size + 'px'
              w2.style.fontSize = size + 'px'
            }
          }
        } else if (val.length < oldVal.length) {
          // 删除
          if (w1.offsetWidth > w2.offsetWidth) {
            if (FontSize < 26) {
              size = 18 + (w1.offsetWidth - w2.offsetWidth) / 10 > 26 ? 26 : 18 + (w1.offsetWidth - w2.offsetWidth) / 10;
              if (size < FontSize) return
              w1.style.fontSize = size + 'px'
              w2.style.fontSize = size + 'px'
            }
          }
        }
      })
      this.setButtonState();
    },
    duration2: function (val, oldVal) {
      if (val.length >= 2) {
        this.duration2 = val.slice(0, 2)
      }
      if (val > 30) {
        this.duration2 = 30;
      }
      this.setButtonState();
    },
    wavesurferAdr: function (val, oldVal) {
      if (val) {
        if (window.innerHeight < 900) {
          window.scrollTo(0, 900 - window.innerHeight);
        }
      }
    },
    downLoadContainerShow: function (val, oldVal) {
      if (val) {
        if (localStorage.getItem("token") != null) {
          let str = localStorage.getItem("downloadMusicID");
          if (str == null) {
            this.$refs.downloadCon.setIsBuy(false);
          } else {
            let arr = str.split(",");
            for (let i = 0; i < arr.length; i++) {
              if (this.musicId == arr[i]) {
                this.$refs.downloadCon.setIsBuy(true);
                break;
              } else {
                this.$refs.downloadCon.setIsBuy(false);
              }
            }
          }
        }
      }
    }
  },
  beforeMount() {
    this.getStyleArr();
    this.duration1 = this.delayDuration(this.duration1)
  },
  mounted() {
  },
  activated() {
    this.getCreateAudioNumNoLogin();
    this.judgeCurrentCollectionStatus();
  },
  methods: {
    changeInputCon(ind) {
      this.hoverInputOrLabel = ind;
      if (this.hoverInputOrLabel == 0) {
        this.$refs.inputContent.focus();
      }
    },
    queryClick(e) {
      if (!this.volumeSliderShow && !this.downLoadContainerShow) {
        return;
      }
      let evt = event || window.event;
      let path = evt.composedPath && evt.composedPath();
      let showFlag;
      if (this.volumeSliderShow) {
        for (let i = 0; i < path.length; i++) {
          if (path[i].className.indexOf("slider") > -1) {
            showFlag = true;
            break;
          } else if (path[i].className == '') {
            showFlag = false;
            break;
          } else {
            showFlag = false;
          }
        }
        this.volumeSliderShow = showFlag;
        if (!this.volumeSliderShow)
          this.$refs.player.closeSlider()
      } else if (this.downLoadContainerShow) {
        for (let i = 0; i < path.length; i++) {
          if (path[i].className.indexOf("home-download") > -1) {
            showFlag = true;
            break;
          } else if (path[i].className == '') {
            showFlag = false;
            break;
          } else {
            showFlag = false;
          }
        }
        this.downLoadContainerShow = showFlag;
      }
    },
    getCreateAudioNumNoLogin() {
      if (localStorage.getItem('token') == null) {
        if (this.getCookie("createAudioNumber") == null) {
          this.setCookie("createAudioNumber", 3);
        }
        this.createAudioNumNoLogin = this.getCookie('createAudioNumber')
      } else {
        this.createAudioNumNoLogin = -1;
      }
    },
    setCookie(name, value) {
      var Days = 1;
      var exp = new Date();
      exp.setTime(exp.getTime() + Days * 24 * 60 * 60 * 1000);
      document.cookie = name + "=" + value + ";expires=" + exp.toGMTString();
      this.createAudioNumNoLogin = this.getCookie("createAudioNumber") == 0
        ? 0 : this.getCookie("createAudioNumber");
      this.setButtonState();
    },
    delCookie(name) {
      document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC;";
    },
    getCookie(name) {
      var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
      if (arr = document.cookie.match(reg))
        return unescape(arr[2]);
      else
        return null;
    },
    judgeCurrentCollectionStatus() {
      if (localStorage.getItem("cancelFavoriteList") == null)
        return;
      console.log(localStorage.getItem("cancelFavoriteList"))
      let cancelFavoriteList = localStorage.getItem("cancelFavoriteList");
      let arr = cancelFavoriteList.split(",");
      let index = arr.findIndex(items => items == this.musicId);
      if (index > -1) {
        this.$refs.player.changeCollectionTipFalse();
      } else {
        this.$refs.player.changeCollectionTipTrue();
      }
    },
    closeDownloadContainer(paymentSource) {
      this.paymentForm = '';
      this.downLoadContainerShow = false;
      if (typeof (paymentSource) == 'string') {
        this.paymentForm = "home" + paymentSource;
        this.paymentCenterShow = true;
        send_record("pay_center_access",
          {
            origin: window.location.pathname.slice(1),
            type: this.paymentForm == "homePayBecomeVip" ? 'buy_vip' : 'single_buy'
          }
        );

      } else {
        this.$refs.player.changeDownloadORLoading(paymentSource, false);
      }
    },
    closePaymentCenter() {
      this.paymentCenterShow = false;
      this.$refs.player.changeDownloadORLoading(this.musicId, false);
    },
    payAudioLoadingOver(loadingOverId) {
      if (loadingOverId == -100) {
        this.$refs.player.changeDownloadORLoading(this.musicId, true);
      }
      this.$refs.player.changeDownloadORLoading(loadingOverId, true);
    },
    changeSliderShow(val) {
      this.volumeSliderShow = val;
      if (this.volumeSliderShow) {
        this.downLoadContainerShow = false;
      }
    },
    setDownLoadContainerShow() {
      this.downLoadContainerShow = true;
      this.$nextTick(() => {
        let clickDom = document.getElementsByClassName("downLoadFile")[0]
        let dom = document.getElementsByClassName("home-download")[0]
        dom.style.left = clickDom.getBoundingClientRect().left - 485 + 120 + 'px';
        dom.style.top = clickDom.getBoundingClientRect().top - 196 + 5 + 'px';
      })
      this.volumeSliderShow = false;
      this.$refs.player.closeSlider()
    },
    inputFocus() {
      this.hoverInputOrLabel = 0;
      this.setButtonState();
    },
    loadFinish() {
      this.loading = false;
      this.setButtonState();
    },
    destoryWave() {
      this.$refs.player.destoryWave();
    },
    collectionLoginSystm() {
      if (this.downLoadContainerShow) {
        this.downLoadContainerShow = false;
      }
      this.$emit("collectionLoginSystm")
    },
    // 一键登录/注册
    loginReg() {
      this.loginStep = 1;
    },
    // 计算过程
    data_process(duration, res) {
      let jsnx = require('jsnetworkx');
      let SAMPLE_RATE = 44100
      let _bpm, _beat_per_phrase, nodes, links, _ending_slices, _tid  // 需要的后端传参
      let phrase_start, phrase_end, p_num = 0, iter_lists = [['S']], curr_pointer = [0], dead_end = false, _phrase_slices = {}// 计算过程中的数据
      let _beats, _bars, _phrases, curr_node, next_list, _graph// 计算过程中的数据
      let phrases = []  // 计算结束的数据

      _bpm = res.data._bpm
      _beat_per_phrase = res.data._beat_per_phrase
      _ending_slices = res.data._ending_slices
      _tid = res.data._tid
      this.localLabelId = _tid
      nodes = res.data.nodes
      links = res.data.links

      // 主体乐句数据准备
      _beats = Math.floor(_bpm / 60 * duration)
      _bars = Math.floor(_beats / 4)
      if (_beats % 4 == 0) {
        _bars -= 1
      }
      _phrases = Math.floor(_bars / (_beat_per_phrase / 4)) || 1 
      sample_per_phrase = Math.floor(Math.round(SAMPLE_RATE * 60 / _bpm * _beat_per_phrase))
      let sample_per_phrase = Math.floor(Math.round(SAMPLE_RATE * 60 / _bpm * _beat_per_phrase))
      let nodes_slice = nodes.slice(1, -2)
      for (let i = 0; i < nodes_slice.length; i++) {
        phrase_start = Math.floor(Math.round(i * sample_per_phrase))
        phrase_end = Math.floor(Math.round((i + 1) * sample_per_phrase))
        if (nodes_slice[i] in _phrase_slices) {
          _phrase_slices[nodes_slice[i]].push([phrase_start, phrase_end])
        } else {
          _phrase_slices[nodes_slice[i]] = [[phrase_start, phrase_end]]
        }
      }
      p_num = _phrases
      _graph = new jsnx.DiGraph()
      _graph.addEdgesFrom(links)
      let re_calc = true
      let re_calc_num = 0
      // 搜索路径
      let last_old_phrases = []
      while (re_calc && re_calc_num < 20) {
        while (phrases.length <= p_num && iter_lists.length > 0) {
          // 选择节点
          curr_node = iter_lists[iter_lists.length - 1][curr_pointer[curr_pointer.length - 1]]
          phrases.push(curr_node)
          if (phrases.length <= p_num) {
            // 导入下一节点路径
            next_list = _graph.successors(curr_node)
            if (next_list.length == 0) {
              dead_end = true
            } else {
              dead_end = false
            }
            if (!dead_end) {
              if (!Array.prototype.derangedArray) {
                // 添加随机打乱的函数
                Array.prototype.derangedArray = function () {
                  for (var j, x, i = this.length; i; j = parseInt(Math.random() * i), x = this[--i], this[i] = this[j], this[j] = x);
                  return this;
                };
              }
              next_list.derangedArray()  //随机打乱顺序
              iter_lists.push(next_list)
              curr_pointer.push(0)
            }
          }
          if (dead_end || (phrases.length > p_num && _graph.successors(curr_node).indexOf('E') == -1)) {
            phrases.pop()
            while (iter_lists.length > 0 && curr_pointer[curr_pointer.length - 1] >= iter_lists[iter_lists.length - 1].length - 1) {
              phrases.pop()
              iter_lists.pop()
              curr_pointer.pop()
            }
            if (curr_pointer.length > 0) {
              let tmp_ = curr_pointer.pop()
              curr_pointer.push(tmp_ + 1)
            }
          }
        }

        console.log("phrases", phrases)
        let old_phrases = checkOld(_tid, phrases)
        last_old_phrases = phrases
        if (old_phrases != true && phrases.length> 0) {
          addOld(_tid, phrases)
          re_calc = false
        }
        re_calc_num += 1
      }
      console.log("last_old_phrases", last_old_phrases)

      if (phrases.length == 0) {
        if (last_old_phrases != null && last_old_phrases.length > 0) {
          phrases = last_old_phrases;
        } else {
          this.loading = false;
          this.setButtonState();
          console.log(re_calc_num, re_calc);
          throw new Error('没有合适的队列');
        }
      }
      let ending_slice
      if (phrases.length == 0) {
        _ending_slices.derangedArray(); //随机打乱顺序
        ending_slice = _ending_slices[0]
      } else {
        ending_slice = phrases[phrases.length - 1]
      }
      this.localPhraseTime = 60 / _bpm * _beat_per_phrase
      this.beforePhraseTime = this.localPhraseTime
      let datas = {
        "_bpm": _bpm,
        "beat_per_phrase": _beat_per_phrase,
        "ending": ending_slice,
        "duration": duration,
        "_tid": _tid,
      }
      datas['slices'] = JSON.stringify(_phrase_slices)
      datas['phrases'] = phrases
      return datas
    },
    getAudio(datas) {
      let phrases = datas['phrases']
      datas['startHalf'] = this.startHalf
      if (this.hoverInputOrLabel == 0) {
        datas['text'] = this.inputContent;
      } else if (this.hoverInputOrLabel == 1) {
        datas['label'] = this.choosedArr
      }
      // TODO: 片头音乐问题,错误警告问题
      axiosPost(SOUNDTRACK,
        qs.stringify(datas, { indices: false })
      ).then(res => {
        this.audioShow = true
        this.structureTimeArr = [0]
        this.wavesurferAdr = res.data.path
        this.musicName = res.data.musicName
        this.musicId = res.data.musicId
        this.$refs.player.changeCollectionTipFalse()
        if (this.showBurstPoint) {
          let havePower = false
          let phrases_split, power_list = [], boom_list = []
          for (let i = 1; i < phrases.length; i++) {
            phrases_split = phrases[i].split("_")
            this.structureTimeArr.push(this.localPhraseTime * (i - 1))
            if (phrases_split.length == 3) {
              havePower = true
              power_list.push(phrases_split[1])
            }
          }
          for (let i = 1; i < power_list.length; i++) {
            boom_list.push(power_list[i] - power_list[i - 1])
          }
          if (havePower) {
            let max_index = this.getMaxIndex(boom_list)
            this.burstPointTime = this.localPhraseTime * (max_index + 1)
            this.localBurstArr = [phrases[max_index + 1], phrases[max_index + 2]]
            this.localBurstIndex = max_index
          }
        } else {
          this.burstPointTime = -1
        }
        this.localFirstBurst = false
        this.setCookie("createAudioNumber", this.createAudioNumNoLogin - 1);
      }).catch(err => {
        this.$message.warning(err.message);
      }).finally(() => {
        this.setButtonState();
        this.loading = false;
      });
    },
    // 生成音乐
    createMusic() {

      if (this.createAudioNumNoLogin == 0) {
        return
      }
      let Duration;
      if (this.showBurstPoint) {
        let durationn = this.duration1.substr(0, 4)
        const min = parseInt(durationn.substr(0, 1))
        const sec = parseInt(durationn.substr(2, 2))
        Duration = min * 60 + sec
      } else {
        Duration = this.duration2
      }
      if (this.hoverInputOrLabel == -1) {
        if (Duration <= 0) {
          throw new Error("请选择标签或输入描述并输入时长")
        } else if (Duration > 0) {
          throw new Error("请选择标签或输入描述")
        }
        return
      } else if (this.hoverInputOrLabel == 0) {
        if (this.inputContent.length == 0 && Duration <= 0) {
          throw new Error("请输入描述并输入时长")
        } else if (this.inputContent.length > 0 && Duration <= 0) {
          throw new Error("请输入时长")
        } else if (this.inputContent.length == 0 && Duration > 0) {
          throw new Error("请输入描述")
        }
      } else if (this.hoverInputOrLabel == 1) {
        if (this.choosedArr.length == 0 && Duration <= 0) {
          throw new Error("请选择标签并输入时长")
        } else if (this.choosedArr.length > 0 && Duration <= 0) {
          throw new Error("请输入时长")
        } else if (this.choosedArr.length == 0 && Duration > 0) {
          throw new Error("请选择标签")
        }
      }

      if (!this.buttonState) {
        return
      }
      // 开始计算，重置数据
      this.offsetSpeed = 1
      this.localBurstArr = []
      this.localFirstBurst = true
      this.startHalf = 0
      this.offsetPhrase = 0,  // 段落长度变化
        // 重置按钮情况
        this.loading = true
      this.buttonState = false
      let duration
      if (this.showBurstPoint) {
        duration = parseInt(this.duration1.substr(0, 1)) * 60 + parseInt(this.duration1.substr(2, 3))
      } else {
        duration = this.duration2
      }
      let style = this.choosedArr
      let fileinfo_data

      if (this.hoverInputOrLabel == 0) {
        fileinfo_data = {
          text: this.inputContent,
          duration: duration
        }
      } else if (this.hoverInputOrLabel == 1) {
        fileinfo_data = {
          label: style,
          duration: duration
        }
      }
      if (this.showBurstPoint) {
        fileinfo_data['boom'] = '1';
      }
      let templateId = localStorage.getItem("templateId")
      fileinfo_data['exclude_tid'] = templateId == null ? null : templateId.split(",");
      axiosPost(FILEINFO, qs.stringify(fileinfo_data, { indices: false })).then((res) => {
        localStorage.setItem("templateId", localStorage.getItem("templateId") == null ? res.data._tid : localStorage.getItem("templateId") + ',' + (res.data._tid))
        let datas
        if (this.showBurstPoint) {
          if ('boom_list_data' in res.data) {
            if (localStorage.getItem("homeBeforeDownloadCount") == null) {
              localStorage.setItem("homeBeforeDownloadCount", 1);
            } else {
              localStorage.setItem("homeBeforeDownloadCount", Number(localStorage.getItem("homeBeforeDownloadCount")) + 1);
            }
            try {
              this.createMusicWithBurstPoint(duration, res)
            } catch (error) {
              this.showBurstPoint = false
              this.$message.warning("不满足生成高能点条件，已关闭高能点")
              datas = this.data_process(duration, res)
              this.getAudio(datas)
            }
            send_record("generate_generate_music_button",
              {
                generate_type: this.showBurstPoint ? '视频配乐' : '片头音乐',
                text_type: this.hoverInputOrLabel ? 'label' : 'text',
                duration: Duration
              }
            )
          } else {
            this.showBurstPoint = false
            this.$message.warning("不满足生成高能点条件，已关闭高能点功能")
            this.createMusic()
            return
          }
        } else {
          // 片头音乐
          datas = this.data_process(duration, res)
          this.getAudio(datas)
        }
      }).catch((err) => {
        console.log(err);
        this.$message.error("生成音乐失败,请重试")
        
      }).finally((fin) => {
        // console.log(fin);
      })
    },
    getAudio_new(datas) {
      datas['offset_speed'] = this.offsetSpeed
      this.burstPointTime = (this.localPhraseBeforeBurst + 1) * this.localPhraseTime
      if (this.startHalf == 1) {
        this.burstPointTime -= 0.5 * this.localPhraseTime
      } else if (this.startHalf == 2) {
        this.burstPointTime += 0.5 * this.localPhraseTime
      }
      datas['BurstTime'] = this.burstPointTime
      let phrases = datas['phrases']
      datas['startHalf'] = this.startHalf
      if (this.hoverInputOrLabel == 0) {
        datas['text'] = this.inputContent;
      } else if (this.hoverInputOrLabel == 1) {
        datas['label'] = this.choosedArr
      }
      axiosPost(SOUNDTRACK,
        qs.stringify(datas, { indices: false })
      ).then(res => {
        this.audioShow = true
        this.structureTimeArr = []
        this.structurePowerArr = []
        this.wavesurferAdr = res.data.path
        this.musicName = res.data.musicName
        this.musicId = res.data.musicId
        this.$refs.player.changeCollectionTipFalse()
        let phrases_split
        for (let i = 1; i < phrases.length; i++) {
          phrases_split = phrases[i].split("_")
          if (this.startHalf == 1 || this.startHalf == 2) {
            if (i == 1) {
              this.structureTimeArr.push(0)
            } else {
              this.structureTimeArr.push(this.localPhraseTime * (i - 1) - this.localPhraseTime * 0.5)
            }
          } else {
            this.structureTimeArr.push(this.localPhraseTime * (i - 1))
          }
          if (phrases_split.length == 3) {
            this.structurePowerArr.push(phrases_split[1][0]) // 取能量级的第一位
          }
        }
        this.burstConArr = [this.beforePhraseTime, datas.duration * datas.offset_speed - this.beforePhraseTime]
        if (this.localFirstBurst) {
          this.localburstPointTime = this.burstPointTime
        }
        this.localFirstBurst = false;
        this.setCookie("createAudioNumber", this.createAudioNumNoLogin - 1);
      }).catch(err => {
        this.$message.warning(err.message);
      }).finally(() => {
        this.setButtonState();
        this.loading = false;
      });
    },
    // 重新生成音乐
    createAgain(time) {
      localStorage.setItem("homeBeforeDownloadCount", Number(localStorage.getItem("homeBeforeDownloadCount")) + 1);

      let offsetPhrase_ori = Math.floor(time / this.beforePhraseTime) - (this.localBurstIndex + 1)  // localBurstIndex是从0开始的，前面是从1开始的
      let other_time = time - offsetPhrase_ori * this.beforePhraseTime - (this.localBurstIndex + 1) * this.beforePhraseTime
      if (other_time < 0.25 * this.beforePhraseTime) {
        this.startHalf = 0
        this.offsetPhrase = offsetPhrase_ori
        this.offsetSpeed = (this.localburstPointTime + this.offsetPhrase * this.beforePhraseTime) / time
      } else if (other_time > 0.25 * this.beforePhraseTime && other_time < 0.5 * this.beforePhraseTime) {
        this.startHalf = 2
        this.offsetPhrase = offsetPhrase_ori
        this.offsetSpeed = (this.localburstPointTime + this.offsetPhrase * this.beforePhraseTime + this.beforePhraseTime * 0.5) / time
      } else if (other_time > 0.5 * this.beforePhraseTime && other_time < 0.75 * this.beforePhraseTime) {
        this.startHalf = 1
        this.offsetPhrase = offsetPhrase_ori + 1
        this.offsetSpeed = (this.localburstPointTime + this.offsetPhrase * this.beforePhraseTime - this.beforePhraseTime * 0.5) / time
      } else if (other_time > 0.75 * this.beforePhraseTime) {
        this.startHalf = 0
        this.offsetPhrase = offsetPhrase_ori + 1
        this.offsetSpeed = (this.localburstPointTime + this.offsetPhrase * this.beforePhraseTime) / time
      }
      this.localPhraseBeforeBurst = this.localBurstIndex + this.offsetPhrase
      // console.log('other_time, this.startHalf, this.offsetSpeed, this.localBurstIndex , this.offsetPhrase',other_time, this.startHalf, this.offsetSpeed,this.localBurstIndex , this.offsetPhrase);
      let duration = this.lastestDuration
      axiosPost(FILEINFO, qs.stringify({ fid: this.localLabelId }, { indices: false })).then((res) => {
        this.createMusicWithBurstPoint(duration, res)
      }).catch((err) => {
        this.loading = false;
        this.renderTip = true
        this.$refs.player.move();
        this.setButtonState();
        this.$message.error(err.message || err.data.msg);
      })
    },
    createMusicWithBurstPoint(duration, res) {
      this.lastestDuration = duration;
      let this_data = {
        offsetSpeed: this.offsetSpeed,
        localLabelId: this.localLabelId,
        localBurstArr: this.localBurstArr,
        localFirstBurst: this.localFirstBurst,
        showBurstPoint: this.showBurstPoint,
        localBurstIndex: this.localBurstIndex,
        localPhraseTime: this.localPhraseTime,
        beforePhraseTime: this.beforePhraseTime,
        localPhraseBeforeBurst: this.localPhraseBeforeBurst,
        startHalf: this.startHalf
      };
      try {
        let datas = data_process_new(duration, res, this_data);
        this_data = datas["this_data"];
        Object.keys(this_data).forEach((key) => {
          this[key] = this_data[key];
        });
        this.getAudio_new(datas["datas"]);
      } catch (err) {
        this.setButtonState();
        this.loading = false;
        throw err
      }
    },
    setButtonState() {
      if (this.loading) {
        this.buttonState = false;
        return;
      }
      if (this.showBurstPoint) {
        // 视频配乐
        if (this.hoverInputOrLabel == 0) {
          // 创意
          if (this.duration1 != "0:00" && this.inputContent.length > 0) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        } else if (this.hoverInputOrLabel == 1) {
          // 标签
          if (this.duration1 != "0:00" && this.choosedArr.length > 0) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        }
      } else {
        // 片头音乐
        if (this.hoverInputOrLabel == 0) {
          // 创意
          if (this.duration2 > 0 && this.inputContent.length > 0) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        } else if (this.hoverInputOrLabel == 1) {
          if (this.duration2 > 0 && this.choosedArr.length > 0) {
            this.buttonState = true;
          } else {
            this.buttonState = false;
          }
        }
      }
    },
    getMaxIndex(arr) {
      var max = arr[0];
      //声明个变量 保存下标值
      var index = 0;
      for (var i = 0; i < arr.length; i++) {
        if (max < arr[i]) {
          max = arr[i];
          index = i;
        }
      }
      return index;
    },
    // 进入输入时长输入框
    intoInput() {
      /**
       * 0/1 0
       * 2/3 2
       * 4   3
       */
      if (this.inputIndex === -1) {
        this.inputIndex = 0
      } else {
        const selection = document.getElementsByClassName('inputDuration')[0].selectionStart
        this.inputIndex = (selection === 0 || selection === 1) ? 0 : ((selection === 2 || selection === 3) ? 2 : (selection - 1))
      }
    },
    // 输入时长
    changeDuration(e) {
      // var eObj = e || window.event;
      // eObj.preventDefault();
      // 37 向左 39向右 8 删除
      e.preventDefault()
      if (e.keyCode == 32) {
        e.preventDefault()
        return
      }
      if (e.key > -1 && e.key < 10) {
        const arr = this.duration1.split('')
        arr[this.inputIndex] = e.key
        this.duration1 = arr.join('')
        this.inputIndex = this.inputIndex === 0 ? this.inputIndex + 2 : this.inputIndex === 3 ? 3 : this.inputIndex + 1
      } else if (e.key === "ArrowLeft") {
        this.inputIndex = this.inputIndex === 2 ? this.inputIndex - 2 : this.inputIndex === 0 ? 0 : this.inputIndex - 1
      } else if (e.key === "ArrowRight") {
        this.inputIndex = this.inputIndex === 0 ? this.inputIndex + 2 : this.inputIndex === 3 ? 3 : this.inputIndex + 1
      } else if (e.key === "Backspace") {
        const timeArr = this.duration1.split('')
        timeArr[this.inputIndex] = 0
        this.duration1 = timeArr.join('')
        this.inputIndex = this.inputIndex === 2 ? this.inputIndex - 2 : this.inputIndex === 0 ? 0 : this.inputIndex - 1
      } else {
        e.preventDefault();
      }
      this.checkTime()
    },
    // 输入时长检测
    checkTime() {
      let duration = this.duration1.substr(0, 4)
      const min = parseInt(duration.substr(0, 1))
      const sec = parseInt(duration.substr(2, 2))
      const Duration = min * 60 + sec
      if (Duration > 300) {
        this.duration1 = '5:00'
      } else if (Duration < 30) {
        this.duration1 = min + ':' + '30'
      } else if (sec > 59) {
        this.duration1 = min + 1 + ':' + (sec - 60 === 0 ? '00' : sec - 60)
      } else if (sec < 10) {
        this.duration1 = min + ':' + '0' + sec
      } else if (isNaN(Duration)) {
        this.duration1 = "0:00"
      }
      this.setButtonState();
    },
    // 处理时间函数 把时间处理为XX:XX
    delayDuration(duration) {
      const min = Math.floor(duration / 60)
      const sec = (duration % 60) > 9 ? Math.round(duration % 60) : '0' + Math.round(duration % 60)
      return min + ':' + sec
    },
    // 获取标签函数
    getStyleArr() {
      axiosGet(LABELARR).then(res => {
        this.genreArr = res.data.genre;
        this.moodArr = res.data.mood;
        this.sceneArr = res.data.scene;
      }).catch(err => {
        this.$message.error(err.data.msg);
      })
    },
    changeShowBurstPoint(tip) {
      if (!tip) {
        this.showBurstPoint = true;
      } else {
        this.showBurstPoint = false;
      }
      this.setButtonState();
    },
    // 30秒不允许输入负号
    inspectDuration(e) {
      // 0-9  48-57  delete 8
      if (e.keyCode == 189 || e.keyCode == 69 || e.keyCode == 190) {
        e.preventDefault();//字母e和-
      }
    },
    // 选择风格
    chooseStyle(index, item) {
      this.hoverInputOrLabel = 1;
      let labelArr = index == 1 ? this.genreArr : index == 2 ? this.sceneArr : this.moodArr;
      let num = this.choosedArr.findIndex(items => items == item);
      let sortIndex;
      if (num > -1) {
        // 点击已选择标签则删除
        this.choosedArr.splice(num, 1);
      } else {
        // 点击未选择标签 先判断类别，如果该类已有选过的标签，则覆盖
        // 如果该类没有有选过的标签，则覆盖
        // this.choosedArr.push(item)
        if (this.choosedArr.length == 0) {
          this.choosedArr.push(item)
        } else {
          for (let i = 0; i < this.choosedArr.length; i++) {
            for (let j = 0; j < labelArr.length; j++) {
              if (labelArr[j] == this.choosedArr[i]) {
                sortIndex = i;
                break
              }
            }
          }
          if (sortIndex > -1) {
            this.choosedArr[sortIndex] = item;
            this.$forceUpdate()
          } else {
            this.choosedArr.push(item)
          }
        }
      }
      this.setButtonState();
    },
    deleteLabel(index) {
      this.choosedArr.splice(index, 1)
      this.setButtonState();
    },
  }
}
</script>


<style lang="scss" scoped>
.home-container {
  margin-left: 88px !important;
  min-width: 1352 !important;
  height: 100vh !important;
  min-height: 900px !important;
  background: #f3f3f3;

  .home-title-container {
    width: calc(100vw - 88px);
    min-width: 1352px;
    height: 208px;
    // background: url(../assets/title_background.png);
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;

    .title-leftContainer {
      .title {
        margin: 45px 0 0 68px;
        font-family: PangMenZhengDao !important;
        font-style: normal;
        font-size: 46px !important;
        line-height: 134.5%;
        font-weight: 400;
        letter-spacing: 0.025em;
        color: rgba(0, 0, 0, 0.8);
      }

      .title-explain {
        font-family: 'PingFang SC';
        font-style: normal;
        font-size: 18px;
        line-height: 100%;
        color: #fff;
        margin-left: 68px;
        font-weight: 400;
        letter-spacing: 0.025em;
        color: rgba(0, 0, 0, 0.8);
      }

      .content-tab {
        margin-left: 68px;
        margin-top: 42px;
        height: 57px;
        width: 244px;
        // padding-top: 28px;
        // background: url(../assets/tab_background.png) no-repeat;
        background-size: 190px 57px;

        .tab-word {
          color: rgba(255, 255, 255, 0.8);
          font-family: 'PingFang SC';
          font-size: 26px;
          line-height: 100%;
          letter-spacing: 5%;
          display: flex;
          justify-content: space-between;
          color: #999999;
          padding-bottom: 8px;
          font-weight: 400;
          font-size: 26px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          span:hover {
            cursor: pointer;
            color: #5490F4;
          }

          .choosedMusic {
            cursor: auto !important;
            color: #5490F4;
            font-weight: 600;
          }

          .choosedMusic:hover {
            color: #5490F4;
          }
        }

        .tip-prompt {
          width: 44px;
          height: 2px;
          background: #5490F4;
          position: relative;
          border-radius: 10px;
          top: 6px;
          margin-top: -8px;
        }
      }

      .TabWordFirst {
        -webkit-animation: turnLeft 200ms linear 1;
        animation: turnLeft 200ms linear 1;

        .tip-prompt {
          -webkit-animation: promptTurnLeft 200ms linear 1;
          animation: promptTurnLeft 200ms linear 1;
          left: 30px;
        }
      }

      .TabWordSecond {
        -webkit-animation: turnRight 200ms linear 1;
        animation: turnRight 200ms linear 1;

        .tip-prompt {
          -webkit-animation: promptTurnRight 200ms linear 1;
          animation: promptTurnRight 200ms linear 1;
          left: 170px;
        }
      }

      @-webkit-keyframes turnLeft {
        0% {
          background-position: 170px;
        }

        100% {
          background-position: 0px;
        }
      }

      @keyframes turnLeft {
        0% {
          background-position: 170px;
        }

        100% {
          background-position: 0px;
        }
      }

      @-webkit-keyframes turnRight {
        0% {
          background-position: 0px;
        }

        100% {
          background-position: 170px;
        }
      }

      @keyframes turnRight {
        0% {
          background-position: 0px;
        }

        100% {
          background-position: 170px;
        }
      }

      @-webkit-keyframes promptTurnLeft {
        0% {
          left: 170px;
        }

        100% {
          left: 30px;
        }
      }

      @keyframes promptTurnLeft {
        0% {
          left: 170px;
        }

        100% {
          left: 30px;
        }
      }

      @-webkit-keyframes promptTurnRight {
        0% {
          left: 30px;
        }

        100% {
          left: 170px;
        }
      }

      @keyframes promptTurnRight {
        0% {
          left: 30px;
        }

        100% {
          left: 170px;
        }
      }
    }

    .title-rightContainer {
      img {
        margin-top: 12px;
        width: 487px;
      }
    }
  }

  #inputContent_box {
    display: inline-block;
    position: fixed;
    color: #fff;
    top: 0;
    height: 54px;
    overflow: hidden;
    font-family: 'PingFang SC';
    font-style: normal;
    font-size: 26px;
    opacity: 0;
  }

  .home-userInput-container {
    margin: 28px 0 0 47px;
    width: calc(100vw - 240px);
    min-width: 1079px;

    .userInput-top-cotainer {
      margin-top: 36px;
      background: #FFFFFF;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04);
      border-radius: 14px;
      overflow: hidden;
    }

    .input-container {
      width: 100%;
      height: 44px;
      display: flex;
      line-height: 44px;
      padding-left: 16px;
      background: #fff;

      .borderBox {
        margin-top: 14px;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.6);
        line-height: 14px;
        text-align: center;

        i {
          display: none;
          font-size: 10px;
          font-weight: 700;
        }
      }

      span {
        font-family: 'PingFang SC';
        font-size: 22px;
        line-height: 44px;
        font-weight: 500;
        letter-spacing: 5%;
        margin-left: 10px;
        margin-right: 24px;
        color: rgba(0, 0, 0, 0.8);
      }

      input {
        font-family: 'PingFang SC';
        font-style: normal;
        font-size: 26px;
        width: calc(100vw - 422px);
        min-width: 900px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        outline: none;
        color: rgba(0, 0, 0, 0.8);
      }

      input::-webkit-input-placeholder {
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .input-container:hover {
      opacity: 1;
    }

    .label-container {
      background: #FFFFFF;
      padding-top: 16px;
      padding-left: 16px;

      .borderBox {
        display: inline-block;
        width: 16px;
        height: 16px;
        border-radius: 3px;
        border: 1px solid rgba(0, 0, 0, 0.6);
        line-height: 14px;
        text-align: center;

        i {
          display: none;
          font-size: 10px;
          font-weight: 700;
        }
      }

      .labelTitle {
        display: inline-block;
        font-family: 'PingFang SC';
        color: rgba(0, 0, 0, 0.8);
        font-size: 22px;
        font-weight: 500;
        line-height: 44px;
        letter-spacing: 5%;
        margin-left: 10px;
        margin-right: 24px;

        .attention {
          text-align: left;
          display: inline;
          position: relative;
          z-index: 3 !important;

          img {
            margin-top: -4px;
            width: 18px;
            height: 18px;
          }

          .attention-container {
            position: absolute;
            left: -5px;
            display: none;
            margin-top: -7px;
            font-family: 'PingFang SC';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: 0.025em;
            color: rgba(255, 255, 255, 0.9);

            .jiantou {
              width: 0;
              height: 0;
              border-bottom: 14px solid rgba(0, 0, 0, 0.8);
              border-right: 14px solid transparent;
              border-left: 14px solid transparent;
            }

            .attention-detail {
              margin-left: -50px;
              padding: 14px 16px;
              width: 305px;
              background: rgba(0, 0, 0, 0.8);
              border-radius: 16px;
            }
          }
        }

        .attention:hover {
          cursor: pointer;

          .attention-container {
            display: block;
            z-index: 3 !important;
          }
        }
      }

      .choosedLabel {
        display: flex;
        color: rgba(0, 0, 0, 0.6);

        .choosedLabel-number {
          i {
            margin-right: 5px;
            position: relative;
            top: 2px;
          }

          span {
            font-size: 12px;
          }
        }

        .choosedLabel-content {
          margin-left: 20px;

          .nothing-choosed {
            height: 24px;
            font-size: 16px;
            font-family: PingFang SC;
            font-style: normal;
            font-weight: normal;
            display: flex;
            letter-spacing: 0.025em;
            color: rgba(0, 0, 0, 0.4);
          }

          div {
            display: flex;
            flex-wrap: wrap;

            .choosedlabel-content {
              margin-right: 12px;
              margin-bottom: 8px;
              border-radius: 19px;
              height: 24px;
              font-family: PingFang SC;
              font-style: normal;
              font-weight: normal;
              font-size: 12px;
              line-height: 131%;
              display: flex;
              align-items: center;
              letter-spacing: 0.025em;
              color: #666666;

              .justContent {
                border-radius: 19px;
                padding: 4px 10px;
                border: 1px solid #e5e5e5;
                display: inline-block;
                margin-right: 6px;
              }

              i {
                margin-left: 6px;
                color: #b8b8b8;
                font-size: 12px;
                cursor: pointer;
              }
            }
          }
        }
      }

      .label-content {
        width: calc((100vw - 268px) * 0.6);
        min-width: 647px;

        .labelArr {
          width: calc((100vw - 268px) * 0.6);
          min-width: 647px;

          .transmitStyle {
            display: inline-block;
            padding: 6px 16px;
            border: 1px solid rgba(102, 102, 102, 0.5);
            font-family: PingFang SC;
            border-radius: 3px;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            letter-spacing: 0.02em;
            color: #666666;
            margin-right: 12px;
            margin-bottom: 12px;
            cursor: pointer;
          }

          .transmitStyle:hover {
            font-weight: 500;
          }

          .genreStyle {
            border: 1px solid #6273bd;
            color: #6273bd;
            border: 1px solid #5490f4;
            color: #5490f4;
            font-weight: 500;
            background: #ffffff;
          }

          .moodStyle {
            border: 1px solid #e0694e;
            color: #e0694e;
            border: 1px solid #5490f4;
            color: #5490f4;
            font-weight: 500;
            background: #ffffff;
          }

          .sceneStyle {
            border: 1px solid #578887;
            color: #578887;
            border: 1px solid #5490f4;
            color: #5490f4;
            font-weight: 500;
            background: #ffffff;
          }
        }
      }
    }

    .label-container:hover {
      cursor: pointer;
      opacity: 1;
    }

    .greyContainer {
      opacity: 0.4;
      cursor: pointer;
    }

    .duration-container {
      height: 71px;
      display: flex;
      justify-content: space-between;
      height: 77px;
      background: #FFFFFF;
      box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.04);
      border-radius: 14px;
      padding-left: 20px;

      .duration-content {
        display: flex;
      }

      div {
        p:nth-child(1) {
          padding-top: 15px;
          font-family: 'PingFang SC';
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          letter-spacing: 5%;
          color: #000000;
        }

        p:nth-child(2) {
          width: 92px;
          text-align: center;
          margin-top: -20px;
          font-family: PingFang SC;
          font-style: normal;
          font-weight: normal;
          font-size: 12px;
          letter-spacing: 0.1em;
          color: #999;
        }
      }

      .inputDuration {
        margin-left: 24px !important;
        margin-top: 12px !important;
        font-size: 36px;
        outline: none;
        width: 78px;
        height: 42px;
        height: 49px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        text-align: center;
        cursor: pointer;
        color: transparent;
        text-shadow: 0 0 0 #434343;
        font-family: Alibaba PuHuiTi;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        display: flex;
        align-items: center;
        text-align: center;
      }

      .input-duration {
        margin-left: 24px !important;
        margin-top: 12px !important;
        text-align: center;
        outline: none;
        width: 45px;
        height: 49px;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: #fff;
        cursor: pointer;
        color: transparent;
        text-shadow: 0 0 0 #434343;
        font-family: Alibaba PuHuiTi;
        font-style: normal;
        font-weight: normal;
        font-size: 36px;
        line-height: 49px;
        letter-spacing: 0.025em;
        color: #434343;
      }

      .input-duration::-webkit-input-placeholder {
        color: #434343;
      }

      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
      }

      input[type="number"] {
        -moz-appearance: textfield;
      }

      .tips-prompt {
        width: 20px;
        height: 2px;
        background: #000;
        margin-top: 60px !important;
        position: relative;
      }
    }

    .button-container {
      width: 240px;
      margin-top: 26px;

      .home-generation {
        margin-top: -8px;

        .createButton {
          width: 240px;
          height: 44px;
          margin-left: 0px !important;
          font-size: 20px;
          background: #000 !important;
          border-radius: 20px;
        }

        .cantcreate {
          color: #999999;
          background: #e5e5e5 !important;
        }

        .custom-loader {
          animation: loader 1s infinite;
          display: flex;
        }

        @-moz-keyframes loader {
          from {
            transform: rotate(0);
          }

          to {
            transform: rotate(360deg);
          }
        }

        @-webkit-keyframes loader {
          from {
            transform: rotate(0);
          }

          to {
            transform: rotate(360deg);
          }
        }

        @-o-keyframes loader {
          from {
            transform: rotate(0);
          }

          to {
            transform: rotate(360deg);
          }
        }

        @keyframes loader {
          from {
            transform: rotate(0);
          }

          to {
            transform: rotate(360deg);
          }
        }
      }

      .createAudioNum-noLogin {
        margin-top: -2px;
        color: #999;
        font-family: PingFang SC;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        text-align: center;

        span {
          margin: 0 0px 0px 5px;
          color: #5490F4;
          cursor: pointer;
        }
      }
    }
  }

  .home-download {
    z-index: 3;
    width: 485px;
    height: 196px;
    position: fixed;
  }

  .home-player {
    // border: 1px saddlebrown solid;
    width: calc(100vw - 268px);
    min-width: 1170px;
    margin-left: 68px !important;
    position: fixed;
    height: 80px;
    z-index: 2;
    bottom: 58px;
    background: #ffffff;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.05);
    border-radius: 20px;
  }

  .home-beian {
    width: calc(100vw - 88px);
    min-width: 1352px;
    position: absolute;
    bottom: 0px;
    text-align: center;
    height: 38px;
    line-height: 38px;
    font-size: 12px;
    color: #B8B8B8;
    font-family: PingFang SC;

    a {
      color: #b8b8b8;
    }

    .beianhao {
      margin-right: 10px;
    }
  }
}
</style>


<style>
.label-content .ant-tabs-bar {
  border-bottom: 1px solid rgba(102, 102, 102, 0.5);
}

/* 选项卡 */
.label-content .ant-tabs-tab {
  padding: 0 !important;
  font-size: 18px;
  line-height: 35px;
  color: #999999;
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
}

.ant-tabs-tab:hover {
  color: #000 !important;
}

.label-content .ant-tabs-top-bar {
  width: 178px !important;
}

.ant-tabs-tab-active {
  /* color: #000 !important; */
  color: #343434 !important;
}

.ant-tabs-ink-bar-animated {
  width: 25px !important;
  margin-left: 5px !important;
  background: #5490f4 !important;
  border-radius: 10px !important;
}
</style>

<style>
/* 按钮阴影消失 */
.home-generation .v-btn--is-elevated {
  box-shadow: none;
}

.home-generation .v-btn--is-elevated {
  box-shadow: none;
}

.home-generation .v-btn--is-elevated {
  box-shadow: none;
}

.home-generation .v-btn--is-elevated {
  box-shadow: none;
}
</style>